<template>
  <modal name="moduloEdit" height="auto" width="410" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="form">
          <div class="bdti-alert bdti-alert-danger" v-if="error">{{error}}</div>
            <div class="form-group">
              <label for="">Sistema</label>
              <div class="form-control" disabled>
                {{sistema ? sistema.descricao : ''}}
              </div>
            </div>
            <div class="form-group">
              <label for="">Descrição (Plural)</label>
              <input class="form-control" v-model="item.descPlural" />
            </div>
            <div class="form-group">
              <label for="">Descrição (Singular)</label>
              <input class="form-control" v-model="item.descSingular" />
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import CloseModal from "../../components/CloseModal";
import _filter from 'lodash/filter'

export default {
  name: "moduloEdit",
  props: [
    "item",
    "update",
    "close",
    "sistemas",
    "descSingular"
  ],
  components: { CloseModal },
  data: function() {
    return {
      error: false,
    };
  },
  computed: {
    sistema() {
      return _filter(this.sistemas,
        (sistema) => +sistema.id === +this.item.sistema_id
      )[0];
    },
  },
  watch: {
    item: function() {
      this.error = false;
    },
  },
  validators: {
    "item.descPlural": (value) => Validator.value(value).required(),
    "item.descSingular": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      let self = this;
      self.error = false;
      this.$validate().then((success) => {
        if (success) {
          let request = this.update(this.item);
          request.then(() => {
            self.close();
          });
          request.catch((error) => {
            self.error = error.message;
          });
        }
      });
    },
  },
};
</script>
